import { template as template_fba311e9908b4eb089113aa5d053615d } from "@ember/template-compiler";
const FKControlMenuContainer = template_fba311e9908b4eb089113aa5d053615d(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
