import { template as template_68c19a1d87b2493eb9a2ec51dc359690 } from "@ember/template-compiler";
const SidebarSectionMessage = template_68c19a1d87b2493eb9a2ec51dc359690(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
